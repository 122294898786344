import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = ({isAuthenticated}) => {
  const [print, setprint] = useState(false);
  const [addrressInfo, setAddrressInfo] = useState("");
  const [emailInfo, setEmailInfo] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  const [logoURL, setLogoURL] = useState("");


  // vs-body-visible
  async function fetchData() {
    try {
      const response = await fetch(`${process.env.REACT_APP_STRAPI_API_URL}/api/footer-navbars?populate=*`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAddrressInfo(data.data[0].attributes.ContactInfo[0].Value);
      setEmailInfo(data.data[0].attributes.ContactInfo[1].Value);
      setPhoneInfo(data.data[0].attributes.ContactInfo[2].Value);
      setLogoURL(data.data[0].attributes.Logo.data.attributes.url);
    } catch (error) {
      console.error('There was a problem fetching the data:', error);
    }
  }
  
  fetchData();
  const navigate = useNavigate()

  useEffect(() => {
    let username = sessionStorage.getItem("phone");
    if (username === "" || username === null) {
      setprint(true);
    }
  }, []);

  return (
    <>
    </>
  );
};
export default Header;

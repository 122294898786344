// when user logged in, use api key
export const GET_CATEGORIES_V1 = "/categoryapi/getcategoriesv1";
// when user is not logged in, without api key
export const GET_CATEGORIES = "/categoryapi/getcategoriesv1";
export const GET_CITIES = "/cityapi/getcities";
export const GET_COUNRIES = "/constantapi/getcountries";
export const GET_USER = "/userapi/getusers";
export const GET_ESTIMATED_PRICE = "/categoryapi/getcategoriesv1";
export const GET_ESTIMATE_PACKAGE_TRIP_FARE =
  "/tripapi/estimatepackagetripfare";
export const GET_ESTIMATE_TRIP_FARE = "/tripapi/estimatetripfare";
export const GET_KIOSK_ESTIMATE_TRIP_FARE = "/tripapi/estimatekiosktripfare";
export const SAVE_TRIP = "/tripapi/save";
export const SAVE_KIOSK_TRIP = "/tripapi/kiosktripsave";
export const UPDATE_TRIP = "/tripapi/updatetrip";
export const GET_TRIPS = "/tripapi/gettrips";
export const FORGOT_PASSWORD = "/userapi/forgetpassword";
export const REGISTER_USER = "/userapi/registration";
export const USER_LOGIN = "/userapi/login";
export const USER_PHONE_LOGIN = "/userapi/phlogin";
export const GET_CONSTANTS = "/constantapi/getconstants";
export const USER_PHONE_REGISTRATION = "/userapi/phregistration";
export const UPDATE_USER_PASSWORD = "/userapi/updateuserpassword";
export const UPDATE_USER_PROFILE = "/userapi/updateuserprofile";
export const ADD_TRANS_WITHOUT_TRIP = "/tronsactionapi/addtranswithouttrip";
export const GET_TRANSACTIONS = "/tronsactionapi/gettransactions";
export const SEND_NOTIFICATION_TO_NEARBYDRIVER = "/driverapi/send-notification-to-nearby-drivers";

//sms
export const SMS = "/tw_whatsapp";
export const KioskSMS = "/tw_kiosk_whatsapp";


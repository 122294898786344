import React from "react";
import Login from "../../../../components/login/Login";

const KioskLoginPage = () => {
  return (
    <div>
      <Login isKioskApp={true} />
    </div>
  );
};

export default KioskLoginPage;

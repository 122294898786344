import { useState } from "react";

const LogoutModal = ({ isOpen, onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (!isOpen) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Please Enter Credentials</h5>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            {/* Dummy input to trick autocomplete */}
            <input
              type="text"
              name="dummy"
              style={{ display: "none" }}
              autoComplete="off"
            />
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="form-control"
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="form-control"
                autoComplete="new-password"
              />
            </div>
            <div className="modal-actions">
              <button className="btn btn-outline-danger" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="btn btn-danger mx-2">
                Logout
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  
      const [addrressInfo, setAddrressInfo] = useState("");
      const [emailInfo, setEmailInfo] = useState("");
      const [phoneInfo, setPhoneInfo] = useState("");
      // const [logoURL, setLogoURL] = useState("");
      const [footerTitle, setFooterTitle] = useState("");
      const [footerDes, setFooterDes] = useState("");
      const [copyright, setCopyright] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Call the function to scroll to top when the component mounts
  
  
  
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_STRAPI_API_URL}/api/footer-navbars?populate=*`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAddrressInfo(data.data[1].attributes.ContactInfo[0].Value);
        setEmailInfo(data.data[1].attributes.ContactInfo[1].Value);
        setPhoneInfo(data.data[1].attributes.ContactInfo[2].Value);
        // setLogoURL(data.data[1].attributes.Logo.data.attributes.url);
        setFooterTitle(data.data[1].attributes.ContactInfo[3].Name);
        setFooterDes(data.data[1].attributes.ContactInfo[3].Value);
        setCopyright(data.data[1].attributes.ContactInfo[4].Value);
      } catch (error) {
        console.error('There was a problem fetching the data:', error);
      }
    }
    
    fetchData();
    
    handleScrollToTop();


    
  }, []);
  return (
    <>
    </>
  );
};
export default Footer;

import React, { useEffect, useState } from "react";

export const Detailpage = ({
  estimatedPriceList,
  distanceMatrix,
  currency,
  closeDetailHandler,
  categories,
  selectedVehicle,
}) => {
  const [tripdata, settripdata] = useState();

  useEffect(() => {
    settripdata(
      categories.filter((item) => item.category_id == selectedVehicle)[0]
    );
  }, [selectedVehicle, categories]);

  return (
    <>
      {" "}
      <React.Fragment>
        <div className="backdrop" >

        <div
          className="center-screen"
          style={{ position: "absolute", minWidth: "375px" }}
        >
          <div
            className="col-md-auto col-sm-auto form-group"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              className="book-btn"
              style={{
                borderRadius: "50%",
                fontSize: 18,
                display: "flex",
                justifyContent: "center", // Centers content horizontally
                alignItems: "center", // Centers content vertically
                position: "absolute",
                top: 10,
                right: 10,
                height: 40,
                width: 40,
                textAlign: "center",
              }}
              onClick={closeDetailHandler}
            >
              x{" "}
            </button>
          </div>
          <div className="offercount">
            <div
              style={{
                textAlign: "center",
                color: "black",
                  marginBottom: 20,
                paddingTop:20
              }}
            >
              {" "}
              <h3>Estimated Fare Details / تفاصيل الأجرة التقديرية</h3>{" "}
            </div>
            <div style={{ display: "grid" }}>
              <div
                className="trip-details-container"
                style={{ display: "grid", position: "relative" }}
              >
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ fontWeight: 500 }}>
                        Minimum Fare / الحد الأدنى للأجرة
                      </th>
                      <td>
                        {currency}&nbsp;
                        {tripdata?.cat_base_price.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ fontWeight: 500 }}>
                        Fare Per Km / الأجرة لكل كيلومتر
                      </th>
                      <td>
                        {currency}&nbsp;{tripdata?.cat_fare_per_km}
                      </td>
                    </tr>

                    {/*    <tr>
                    <th scope="row">trip_pay_amount</th>
                    <td>
                      {currency}&nbsp;
                      {(estimatedPriceList.trip_pay_amount).toFixed(2)}
                    </td>
                  </tr> */}
                    {distanceMatrix.distance != 0 && (
                      <tr>
                        <th scope="row" style={{ fontWeight: 500 }}>
                          Estimated Fare / الأجرة المقدرة
                        </th>
                        <td>
                          {currency}&nbsp;
                          {estimatedPriceList.kiosk_pay_amt.toFixed(2)}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th scope="row" style={{ fontWeight: 500 }}>
                        Distance / المسافة
                      </th>
                      <td>
                        {distanceMatrix.distance != 0
                          ? distanceMatrix.distance
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row" style={{ fontWeight: 500 }}>
                        Duration / المدة
                      </th>
                      <td>
                        {distanceMatrix.duration != 0
                          ? distanceMatrix.duration
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          </div>
      </React.Fragment>
    </>
  );
};

import React, { useEffect, useState } from "react";
import KioskBookingForm from "../../../components/forms/KioskBookingForm";
import "../kiosk-app.css";
import { USER_LOGIN } from "../../../utils/Endpoint";
import ApiService from "../../../utils/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoutModal from "../components/LogoutModal";
import KioskHotelBookingForm from "../../../components/forms/KioskBookingHotelForm";

const KioskHomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId] = useState(sessionStorage.getItem("userid") || "");
  const navigate = useNavigate();
  const location = useLocation();
  const kiosk_type =
    location.state?.kiosk_type == undefined
      ? sessionStorage.getItem("kiosk_ride_type")
      : location.state?.kiosk_type; // Safely access the passed valu
  const handleLogoutClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogoutSubmit = async (email, password) => {
    try {
      let data = {
        is_fr: 1,
        u_email: email,
        u_password: password,
        is_login: 0,
      };

      const response = await ApiService.post(`${USER_LOGIN}`, data);
      if (response.status === 200) {
        disableFullScreenMode();
        let user_email = sessionStorage.getItem("email");
        if (email.toLowerCase() === user_email.toLowerCase()) {
          sessionStorage.clear();
          navigate("/login");
          toast.success("Logged out successfully");
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
      toast.error("Invalid credentials");
    } finally {
      handleCloseModal();
    }
  };

  const enableFullScreenMode = () => {
    var elem = document.documentElement;

    // Check for fullscreen support in different browsers and request fullscreen
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  // const enableFullScreenMode = () => {
  //   if (!document.fullscreenElement) {
  //     document.documentElement.requestFullscreen();
  //   }
  // };

  const disableFullScreenMode = () => {
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" && document.fullscreenElement) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (!userId) {
      navigate("/login");
    } else {
      enableFullScreenMode();
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userId]);

  return (
    <div className="kiosk-app-container">
      <div className="logout-button">
        <i
          className="fa fa-power-off"
          aria-hidden="true"
          onClick={handleLogoutClick}
        ></i>
      </div>
      <div className="form-container col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
        {kiosk_type?.toLowerCase() == "kiosk" ? (
          <KioskBookingForm />
        ) : (
          <KioskHotelBookingForm />
        )}
      </div>
      <LogoutModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleLogoutSubmit}
      />
    </div>
  );
};

export default KioskHomePage;

import React, { useState, useEffect, useRef } from "react";

const IdleVideo = () => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimeoutRef = useRef(null);
  const idleTime = 60000; // 60 seconds of inactivity
  const videoRef = useRef(null);

  // Function to reset idle timer
  const resetIdleTimer = () => {
    setIsIdle(false);
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }
    idleTimeoutRef.current = setTimeout(() => {
      setIsIdle(true);
    }, idleTime);
  };

  // Set up event listeners for user activity
  useEffect(() => {
    // Reset the timer on activity
    const events = ["mousemove", "keydown", "scroll", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    // Clean up event listeners on unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
    };
  }, []);

  // Effect to start video playback when idle
  useEffect(() => {
    if (isIdle && videoRef.current) {
      videoRef.current.play();
    }
  }, [isIdle]);

  return (
    <div className="idle" style={{ display: isIdle ? "block" : "none" }}>
      <video
        ref={videoRef}
        autoPlay
        muted // Add muted attribute to allow autoplay
        loop // Optional: Use this to loop the video continuously
        className="full-screen-video"
      >
        <source
          src="images/screen.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default IdleVideo;

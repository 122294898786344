import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainRoutes from "./routes/MainRoutes";
import IdleVideo from "./components/screenIdle";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("auth") ? true : false
  );

  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem("auth") ? true : false);
  }, []);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Callback when the Google Maps script is loaded
  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };
  return (
    <>
      <IdleVideo />
      <MainRoutes setIsAuthenticated={setIsAuthenticated} />{" "}
      {/* Pass setIsAuthenticated to the MainRoutes component */}
      <ToastContainer />
    </>
  );
}

export default App;
